import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "./link";

const BoxEngage = () => {
  const { programma, tunnel, idee, casestudy, keynote, workshop, trend, expo, networking } =
    useStaticQuery(graphql`
      {
        programma: file(relativePath: { eq: "box-programma.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: BLURRED)
          }
        }
        tunnel: file(relativePath: { eq: "bg-tunnel.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        idee: file(relativePath: { eq: "engage/idee.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        casestudy: file(relativePath: { eq: "engage/case-study.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        keynote: file(relativePath: { eq: "engage/keynote.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        workshop: file(relativePath: { eq: "engage/workshop.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        trend: file(relativePath: { eq: "engage/trend.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        expo: file(relativePath: { eq: "engage/expo.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        networking: file(relativePath: { eq: "engage/networking.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    `);
  return (
    <>
      <section className="section">
        <div className="box-engage">
          <div className="box-engage__circle"></div>
          <GatsbyImage
            image={getImage(tunnel)}
            alt="img background megamenu"
            objectPosition="center bottom"
            className="box-engage__bg-img"
          />
          <div className="box-engage__bg-light">
            <GatsbyImage
              image={getImage(programma)}
              alt="img animazione programma"
              objectPosition="center bottom"
              className="animation-circle animation-circle--img"
            />
            <div className="container-md">
              <div className="row">
                <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                  <div className="section__name">Programma +</div>
                  <div className="box-engage__section-title">Programma spaziale</div>
                  <div className="section__subject">Workshop, keynote, case study, networking.</div>
                </div>
              </div>
              <div className="row mt-md-5">
                <div className="col-12 col-sm-10 offset-sm-1 col-md-7 offset-md-0 order-md-2">
                  <div className="row my-5 my-md-0">
                    <div className="col-7">
                      <div className="box-engage__claim text-uppercase">
                        <p>
                          Idee, best practice ed esempi per creare spazi digitali che migliorino
                          produttività, collaborazione, sicurezza, employee experience.
                        </p>
                      </div>
                    </div>
                    <div className="col-5">
                      <GatsbyImage
                        image={getImage(idee)}
                        alt="img idee"
                        objectPosition="center center"
                        className="box-engage__img"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <GatsbyImage
                        image={getImage(keynote)}
                        alt="img keynote"
                        objectPosition="center center"
                        className="box-engage__img"
                      />
                    </div>
                    <div className="col-7 col-sm-6 col-md-5 d-flex align-items-center">
                      <div>
                        <div className="box-engage__title text-uppercase">Keynote</div>
                        <div className="box-engage__text">
                          <p>
                            I maggiori esperti italiani ed internazionali a confronto per
                            condividere i trend e le novità nell'ambito della comunicazione interna.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-8 offset-sm-2 col-md-5 offset-md-0 order-md-1">
                  <div className="row pt-5 pt-sm-0 pb-md-5 mb-md-5">
                    <div className="col-7 col-sm-5 d-flex align-items-center">
                      <div>
                        <div className="box-engage__title text-uppercase">Case study</div>
                        <div className="box-engage__text">
                          <p>
                            Contaminazione di esperienze: i case study sono l'occasione per
                            confrontarsi con le best practices di settore.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 col-sm-7">
                      <GatsbyImage
                        image={getImage(casestudy)}
                        alt="img casestudy"
                        objectPosition="center center"
                        className="box-engage__img"
                      />
                    </div>
                  </div>
                  <div className="row pt-5">
                    <div className="col-7 col-sm-5 order-2 order-sm-1 d-flex align-items-center">
                      <div>
                        <div className="box-engage__title text-uppercase">Workshop</div>
                        <div className="box-engage__text">
                          <p>
                            Momenti formativi e pratici di gruppo su temi specifici, per
                            sperimentare e confrontarsi con altri partecipanti.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 col-sm-7 order-1 order-sm-2">
                      <GatsbyImage
                        image={getImage(workshop)}
                        alt="img workshop"
                        objectPosition="center center"
                        className="box-engage__img"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 col-sm-10 offset-sm-1 col-md-5 offset-md-0">
                  <div className="row">
                    <div className="col-7 col-sm-5 d-flex align-items-center">
                      <div>
                        <div className="box-engage__title text-uppercase">Networking</div>
                        <div className="box-engage__text">
                          <p>
                            Tavole rotonde, presentazioni di libri, opportunità di incontrare altre
                            realtà, ascoltare e conoscersi.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 col-sm-7">
                      <GatsbyImage
                        image={getImage(networking)}
                        alt="img networking"
                        objectPosition="center center"
                        className="box-engage__img"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-10 offset-sm-1 col-md-7 offset-md-0">
                  <div className="row my-5 my-md-0">
                    <div className="col-7 order-2 order-sm-1">
                      <div className="box-engage__title text-uppercase">Trend</div>
                      <div className="box-engage__text">
                        <p>
                          Analisi delle tendenze e dei nuovi standard con esempi tratti da
                          esperienze nazionali e internazionali sui temi emergenti del settore.
                        </p>
                      </div>
                    </div>
                    <div className="col-5 order-1 order-sm-2">
                      <GatsbyImage
                        image={getImage(trend)}
                        alt="img trend"
                        objectPosition="center center"
                        className="box-engage__img"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 order-2 order-sm-1">
                      <GatsbyImage
                        image={getImage(expo)}
                        alt="img expo"
                        objectPosition="center center"
                        className="box-engage__img"
                      />
                    </div>
                    <div className="col-7 col-sm-6 col-md-5 order-1 order-sm-2 d-flex align-items-center">
                      <div>
                        <div className="box-engage__title text-uppercase">Expo</div>
                        <div className="box-engage__text">
                          <p>
                            Una galleria di progetti reali illustrati, selezionati tra quelli del
                            nostro Premio annuale Intranet Italia Champions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 text-center">
                  {/* <p>Stiamo lavorando al programma del prossimo evento.</p>
                  <p>Torna a trovarci tra qualche mese.</p>
                  <p>
                    Se non lo hai già fatto, puoi iscriverti alla nostra{" "}
                    <strong>
                      <Link to="/#newsletter" title="Form iscrizione newsletter">
                        newsletter
                      </Link>
                    </strong>{" "}
                    per avere tutti gli aggiornamenti sull'evento.
                  </p> */}
                  <div className="mt-5">
                    <Link to="/programma/" className="btn-link btn-link--dark">
                      <span className="btn-link__text">
                        Scopri il <span className="d-block bold">programma</span>
                      </span>
                      <span className="btn-link__circle"></span>
                    </Link>
                  </div>
                </div>
              </div>
              {/*
              <div className="row mt-5 mt-md-0">
                <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 text-center text-sm-start">
                  <Link to="/programma/" className="btn-link btn-link--dark">
                    <span className="btn-link__text">
                      Scopri il <span className="d-block bold">programma</span>
                    </span>
                    <span className="btn-link__circle"></span>
                  </Link>
                </div>
              </div>
              */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BoxEngage;
